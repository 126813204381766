
$(document).ready(function(){
    $('#ChartBtn').click(function(evt){
        evt.preventDefault();
        $(this).parent().addClass('current');
        $('#chartPart').show();

        $('#TableBtn').parent().removeClass('current');
        $('#tablePart').hide()
    });

    $('#TableBtn').click(function(evt){
        evt.preventDefault();
        $(this).parent().addClass('current');
        $('#tablePart').show();
        $('#ChartBtn').parent().removeClass('current');
        $('#chartPart').hide();
    });

    $(window).scroll(function(e){
        var scrollTop = $(window).scrollTop();
        var docHeight = $(document).height();
        var winHeight = $(window).height();
        var scrollPercent = (scrollTop) / (docHeight - winHeight);
        var scrollPercentRounded = Math.round(scrollPercent*100);

        if(scrollPercentRounded > 15 ){
            $("#go-top-bouton").addClass('active');
        }else{
            $("#go-top-bouton").removeClass('active');
        }
    });
    $("#go-top-bouton").click(function () {
        $("html, body").animate({scrollTop:0}, '500');
    });

    // Code JS listing pays / Company

    var offsets = $('#country-menu-container').offset();
    if (offsets){
        var top = offsets.top;
        var left = offsets.left;

        $(window).scroll(function (e) {
            if($(window).scrollTop() > top - 30){
                $('#country-menu-container').addClass("fixed-top");
            }else{
                $('#country-menu-container').removeClass("fixed-top");
            }
        });
    }




    var scrollTop = $(".country-wrap").scrollTop();
    var docHeight = $("#country-filter").height();
    var winHeight = $(".country-wrap").outerHeight();
    if(docHeight <= winHeight){
        $(".country-scroll-top").hide();
        $(".country-scroll-bottom").hide();
    }else {
        $(".country-wrap").scroll(function(e){
            var scrollTop = $(".country-wrap").scrollTop();
            var docHeight = $("#country-filter").height();
            var winHeight = $(".country-wrap").outerHeight();

            if(scrollTop == 0){
                $(".country-scroll-top").hide()
            }else if(scrollTop == docHeight - winHeight + 8){
                $(".country-scroll-bottom").hide();
            }else {
                $(".country-scroll-top").show();
                $(".country-scroll-bottom").show();
            }
        });
    }

    $('.country-scroll-top').click(function () {
        var y = $(".country-wrap").scrollTop();

        $(".country-wrap").animate({
            scrollTop: y - 70
        }, 250);
    });
    $('.country-scroll-bottom').click(function () {
        var y = $(".country-wrap").scrollTop();

        $(".country-wrap").animate({
            scrollTop: y + 70
        }, 250);
    });
}());

function addOverlay(AllCanvas) {
    $('body').prepend('<div id="overlayPDF"><div><span>Loading</span><span id="dots"> </span></div></div>');
    $('#overlayPDF div').css('margin-top',$('body').height()/2);
    var dotsBlink = function() {
        var dots = {' ': '.', '.': '..', '..': '...', '...': ' '};

        $('#dots').html(dots[$('#dots').html()]);
        window.setTimeout(dotsBlink, 300);
    };
    dotsBlink();

    $.each(AllCanvas, function(i, v){
        resizeCanvas(v);
    });
}

function resizeCanvas(selector) {
    var $parent = selector.closest("div[class^='col-md']");
    if ($parent.hasClass('col-md-5')) {

        $parent.addClass('printCanvasTmp-5');
    }
    if ($parent.hasClass('col-md-7')) {
        $parent.addClass('printCanvasTmp-7');
    }
    $parent.removeClass('col-md-5 col-md-7');
}

function removeOverlay(AllCanvas) {

    $.each(AllCanvas, function(i, v){
        undoResizeCanvas(v);
    });

    setTimeout(function() {
        $('#overlayPDF').remove();
    }, 500);
}

function undoResizeCanvas(selector) {
    var $parent = selector.closest("div[class^='printCanvasTmp']");
    if ($parent.hasClass('printCanvasTmp-5')) {
        $parent.addClass('col-md-5');
    }
    if ($parent.hasClass('printCanvasTmp-7')) {
        $parent.addClass('col-md-7');
    }
    $parent.removeClass('printCanvasTmp-7 printCanvasTmp-5');
}

function checkDisabledRow(selector, table){
    selector.each(function(){
        if($(this).hasClass('disabled')){
            table.push($(this).index())
        }

        if($(this).find('td:first').hasClass('parent')){
            table.push($(this).index())
        }

    });
}

function checkSecretData(selector, table){
    selector.each(function(){
        if(typeof($(this).attr('style')) !== "undefined"){
            $daddy = $(this).parent();
            $bgColor = $(this).css('background-color');
            table.push([$daddy.index(), [$(this).index(),$bgColor]]);
        }
    });
}

function initPDF(){
    doc = new jsPDF({
        unit: 'pt',
        format: 'a4',
        orientation: 'landscape'
    });
}

function savePDF(name) {
    doc.save(name);
}

function addFrontPage(title, survey, countries, products) {
    var lMargin=30; //left margin in pt
    var rMargin=30; //right margin in pt
    var pdfInPT=842;  // width of A4 in pt

    doc.setFontSize(50);
    doc.text(30,65, title);

    doc.setFontSize(35);
    doc.text(30,150, survey);

    doc.setFontSize(20);
    var cLines = doc.splitTextToSize('Countries: ' + countries, (pdfInPT - lMargin - rMargin));
    doc.text(lMargin, 200, cLines);

    if (products) {
        doc.setFontSize(16);
        var pLines = doc.splitTextToSize('Products: ' + products, (pdfInPT - lMargin - rMargin));
        doc.text(lMargin, 350, pLines);
    }

    doc.addPage();
}

function drawGraph(idMarket, idmarketProduct, idconsumable, idfluxCover){
    var graph0 = document.getElementById(idMarket).toDataURL();
    // addImage (image, format,x,y, width, height, alias(?), compresssion( if jpeg), rotation
    doc.addImage(graph0, 'png', (842-650)/2, 30, 650, 563);
    doc.addPage();

    var graph1 = document.getElementById(idmarketProduct).toDataURL();
    doc.addImage(graph1, 'png', 0, 30, 910/1.09, 563/1.09);
    doc.addPage();

    if(idconsumable !== ''){
        var graph2 = document.getElementById(idconsumable).toDataURL();
        doc.addImage(graph2, 'png', 0, 30, 910/1.09, 563/1.09);
        doc.addPage();
    }

    if(idfluxCover !== ''){
        var graph3 = document.getElementById(idfluxCover).toDataURL();
        doc.addImage(graph3, 'png', (842-650)/2, 30, 650, 563);
        doc.addPage();
    }
}

function drawTable(idTable,indexDisabledRow, indexSecretData){
    var elem = document.getElementById(idTable);
    var res = doc.autoTableHtmlToJson(elem, true);
    var options = {
        // Styling
        theme: 'striped', // 'striped', 'grid' or 'plain'
        styles: {
            cellPadding: 5, // a number, array or object (see margin below)
            fontSize: 8,
            font: "helvetica", // helvetica, times, courier
            lineColor: 200,
            lineWidth: 0,
            fontStyle: 'normal', // normal, bold, italic, bolditalic
            overflow: 'linebreak', // visible, hidden, ellipsize or linebreak
            fillColor: false, // false for transparent or a color as described below
            textColor: 20,
            halign: 'left', // left, center, right
            valign: 'middle', // top, middle, bottom
            columnWidth: 'auto' // 'auto', 'wrap' or a number
        },
        headerStyles: {
            fontStyle: 'bold', // normal, bold, italic, bolditalic
            fontSize: 8.5,
            font: "helvetica", // helvetica, times, courier
            fillColor:  102, // false for transparent or a color as described below
            textColor: '#ffffff',

        },
        bodyStyles: {},
        alternateRowStyles: {},
        columnStyles: {},

        // Properties
        startY: 30, // false (indicates margin top value) or a number
        margin: 19, // a number, array or object
        pageBreak: 'auto', // 'auto', 'avoid'
        tableWidth: 'auto', // 'auto', 'wrap' or a number,
        showHeader: 'everyPage', // 'everyPage', 'firstPage', 'never',
        tableLineColor: 200, // number, array (see color section below)
        tableLineWidth: 0,
        // Hooks
        drawCell: function (cell, data) {

            var cellArray = [data.row.index, cell.raw.cellIndex];

            if ($.inArray(data.row.index,indexDisabledRow) != -1) {
                // doc.setTextColor(255, 255, 255);
                doc.setFillColor(154, 154, 154);
                cell.styles.fillColor = true;
            }

            if(indexSecretData != null){
                for(var c = 0; c < indexSecretData.length; c++){
                    if(cellArray[0] == indexSecretData[c][0]){
                        if(cellArray[1] == indexSecretData[c][1][0]){

                            var rgb = indexSecretData[c][1][1];
                            rgb = rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

                            doc.setFillColor(parseInt(rgb[1]), parseInt(rgb[2]), parseInt(rgb[3]));
                            cell.styles.fillColor = true;

                        }
                    }
                };
            }
        },
    };

    doc.setFillColor(180,207,234);
    doc.rect(20, 10, 10,10, 'F');
    doc.setFontSize(10);

    doc.setTextColor(0, 0, 0);
    doc.text('Revisited data', 35, 20);

    doc.setFillColor(240,191,73);
    doc.rect(110, 10,10,10, 'F');
    doc.text('Estimated data', 125, 20);

    doc.setFillColor(211,157,195);
    doc.rect(202, 10,10,10, 'F');
    doc.text('Secret data', 217, 20);

    doc.autoTable(res.columns, res.data, options);
}